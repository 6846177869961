import { Link, useSearchParams } from '@remix-run/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Select from 'react-select';
import { useDebounce } from 'react-use';
import useSWRInfinite from 'swr/infinite';
import useSWRMutation from 'swr/mutation';

import {
  type DtoUpdateRoleRequest,
  type DtoUser,
  type DtoUsersResponse,
  type QueryUsersParams,
} from '@lp-lib/api-service-client/public';

import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import {
  type Organization,
  OrganizerUtils,
  Role,
  RoleUtils,
  type User,
} from '../../../types';
import { fromDTOUser } from '../../../utils/api-dto';
import { err2s } from '../../../utils/common';
import { DateUtils } from '../../../utils/date';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { IconCopyButton } from '../../common/CopyButton';
import { type Option } from '../../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { AccountIcon } from '../../icons/AccountIcon';
import { GreenCheckIcon } from '../../icons/GreenCheckIcon';
import { NewWindowIcon } from '../../icons/NewWindowIcon';
import { OptionsIcon } from '../../icons/OptionsIcon';
import { TeamIcon } from '../../icons/TeamIcon';
import { XIcon } from '../../icons/XIcon';
import { Loading } from '../../Loading';
import { FAKE_ORGANIZATION, OrganizationSelect } from '../../Organization';

function useUserFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const filters: QueryUsersParams = {};
    if (searchParams.has('uid')) {
      filters.uid = searchParams.get('uid') ?? undefined;
    }
    if (searchParams.has('email')) {
      filters.email = searchParams.get('email') ?? undefined;
    }
    if (searchParams.has('orgId')) {
      filters.orgId = searchParams.get('orgId') ?? undefined;
    }
    if (searchParams.has('role')) {
      filters.role = RoleUtils.parse(searchParams.get('role') ?? '');
    }
    if (searchParams.has('searchGuestUsers')) {
      filters.searchGuestUsers =
        (searchParams.get('searchGuestUsers') ?? '') === 'true';
    }
    return filters;
  }, [searchParams]);

  const setFilters = useCallback(
    (filters: QueryUsersParams) => {
      setSearchParams((prev) => {
        for (const filterName in filters) {
          const value = filters[filterName as keyof QueryUsersParams];
          if (value === undefined) {
            prev.delete(filterName);
          } else {
            prev.set(filterName, String(value));
          }
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('uid');
      prev.delete('email');
      prev.delete('orgId');
      prev.delete('role');
      prev.delete('includeGuestUsers');
      return prev;
    });
  }, [setSearchParams]);

  return {
    filters,
    setFilters,
    clearFilters,
  };
}

const userRoleOptions = [
  {
    label: 'User',
    value: Role.User,
  },
  {
    label: 'Admin',
    value: Role.Admin,
  },
];

const roleSelectStyles = buildReactSelectStyles<Option<Role>>({
  override: {
    control: {
      height: '40px',
    },
  },
});

function UserFilterControl(): JSX.Element {
  const { filters, setFilters } = useUserFilters();
  const [uidInput, setUidInput] = useState(filters.uid ?? '');
  useDebounce(
    () => {
      setFilters({ ...filters, uid: uidInput || undefined });
    },
    300,
    [uidInput]
  );
  const [emailInput, setEmailInput] = useState(filters.email ?? '');
  useDebounce(
    () => {
      setFilters({ ...filters, email: emailInput || undefined });
    },
    300,
    [emailInput]
  );
  useEffect(() => {
    setUidInput(filters.uid ?? '');
    setEmailInput(filters.email ?? '');
  }, [filters.uid, filters.email]);
  return (
    <div>
      <div className='flex items-center gap-4'>
        <div className='w-50'>
          <input
            className='w-full field h-10 text-sms m-0'
            type='text'
            value={uidInput}
            onChange={(e) => setUidInput(e.target.value)}
            placeholder='Find with id'
          />
        </div>
        <div className='w-50'>
          <input
            className='w-full field h-10 text-sms m-0'
            type='text'
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            placeholder='Search by email'
          />
        </div>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={filters.orgId ?? null}
            onChange={(org) =>
              setFilters({
                ...filters,
                orgId: org?.id,
              })
            }
            isClearable
          />
        </div>
        <div className='w-50'>
          <Select<Option<Role>>
            styles={roleSelectStyles}
            options={userRoleOptions}
            value={
              userRoleOptions.find((o) => o.value === filters.role) ?? null
            }
            onChange={(r) =>
              setFilters({ ...filters, role: r?.value ?? undefined })
            }
            placeholder='Role'
            isClearable
          />
        </div>
        <div className='flex items-center gap-2'>
          <input
            type='checkbox'
            className='checkbox-dark'
            checked={filters.searchGuestUsers ?? false}
            onChange={(e) =>
              setFilters({
                ...filters,
                searchGuestUsers: e.target.checked ? true : undefined,
              })
            }
          />
          <p className='text-sms text-icon-gray'>Search Guest Users</p>
        </div>
      </div>
    </div>
  );
}

function ActionSheetMenuItem(props: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}) {
  const { icon, text, onClick, disabled } = props;
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
  };
  return (
    <button
      className={`
        btn w-full h-8 px-2 hover:bg-dark-gray rounded-lg
        flex items-center gap-2 text-3xs
        ${props.className ?? ''}
      `}
      onClick={handleClick}
      type='button'
      disabled={disabled}
    >
      {icon}
      {text}
    </button>
  );
}

function ChangeRoleModal(props: {
  initial: User;
  onCancel: () => void;
  onSave: (role: Role) => void;
}) {
  const user = props.initial;
  const { trigger, isMutating, error } = useSWRMutation(
    `/users/${user.id}/role`,
    async (_, { arg }: { arg: DtoUpdateRoleRequest }) =>
      await apiService.user.updateRole(user.id, arg)
  );
  const [role, setRole] = useState<Role>(user.role);
  const isAdminAnOption = useMemo(() => {
    return user.email && user.email.endsWith('@lunapark.com');
  }, [user.email]);
  const handleSave = useLiveCallback(async () => {
    // TODO(falcon): reckon with types
    await trigger({ role: role as never });
    props.onSave(role);
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-142 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>Change User Role</div>
          <div className='text-sms text-icon-gray'>{user.email}</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Role</div>
              <div className='text-xs text-icon-gray'>
                Defines the user’s permissions and access.{' '}
                <span className='text-tertiary'>
                  Only LP emails can be made Admins.
                </span>
              </div>
            </div>
            <Select<Option<Role>>
              styles={roleSelectStyles}
              options={userRoleOptions}
              value={userRoleOptions.find((o) => o.value === role)}
              onChange={(r) => setRole((prev) => r?.value ?? prev)}
              placeholder='Role'
              isOptionDisabled={(o) => {
                return o.value === Role.Admin && !isAdminAnOption;
              }}
              isDisabled={isMutating}
            />
          </div>
        </div>

        {error && (
          <div className='text-red-002 text-xs py-3'>{err2s(error)}</div>
        )}

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
            disabled={isMutating}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={isMutating}
          >
            {isMutating ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}

function ChangeOrgModal(props: {
  initial: User;
  onCancel: () => void;
  onSave: (org: Organization) => void;
}) {
  const user = props.initial;
  const { trigger, isMutating, error } = useSWRMutation(
    `/users/${user.id}/role`,
    async (_, { arg }: { arg: { orgId: string } }) => {
      const orgId = arg.orgId;
      if (user.organizer?.orgId && orgId === FAKE_ORGANIZATION.id) {
        await apiService.organization.deleteOrganizer(
          user.organizer.orgId,
          user.id
        );
      } else if (
        !user.organizer?.orgId &&
        orgId !== FAKE_ORGANIZATION.id &&
        user.email
      ) {
        await apiService.organization.inviteOrganizers(orgId, {
          invitedUsers: [{ email: user.email }],
          webEndpoint: window.location.origin,
          sendEmail: false,
        });
      } else if (
        user.organizer?.orgId &&
        orgId !== user.organizer.orgId &&
        user.email
      ) {
        // remove from current org...
        await apiService.organization.deleteOrganizer(
          user.organizer.orgId,
          user.id
        );
        // add to new one...
        await apiService.organization.inviteOrganizers(orgId, {
          invitedUsers: [{ email: user.email }],
          webEndpoint: window.location.origin,
          sendEmail: false,
        });
      }
    }
  );
  const [org, setOrg] = useState<Organization | undefined>(
    props.initial.organizer?.organization ?? undefined
  );
  const handleSave = useLiveCallback(async () => {
    if (!org) return;
    await trigger({ orgId: org.id });
    props.onSave(org);
  });

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-142 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-2xl'>Change Organization</div>
          <div className='text-sms text-icon-gray'>{props.initial.email}</div>
        </div>

        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-4'>
          <div className='grid grid-cols-2 items-start gap-x-6'>
            <div className='flex flex-col gap-1'>
              <div className='font-bold'>Organization</div>
              <div className='text-xs text-icon-gray'>
                Changing a user’s organization will remove them from their
                existing org if they have one. Use `N/A` to remove them from
                their current organization.
              </div>
            </div>
            <OrganizationSelect
              orgId={org?.id ?? null}
              onChange={(o) => setOrg(o ?? undefined)}
              placeholder='Select organization'
            />
          </div>
        </div>

        {error && (
          <div className='text-red-002 text-xs py-3'>{err2s(error)}</div>
        )}

        <div className='mt-auto w-full pb-2 flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={props.onCancel}
            disabled={isMutating}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={org === undefined || isMutating}
          >
            {isMutating ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}

export function UserActionSheet(props: {
  user: User;
  disabled?: boolean;
  revalidate: () => void;
}) {
  const { user, disabled, revalidate } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: 'auto',
    });

  const handleChangeRoleClick = useLiveCallback(async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = () => {
          revalidate();
          p.internalOnConfirm();
        };
        return (
          <ChangeRoleModal
            initial={user}
            onCancel={p.internalOnCancel}
            onSave={handleSave}
          />
        );
      },
    });
  });

  const handleChangeOrgClick = useLiveCallback(async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = () => {
          revalidate();
          p.internalOnConfirm();
        };
        return (
          <ChangeOrgModal
            initial={user}
            onCancel={p.internalOnCancel}
            onSave={handleSave}
          />
        );
      },
    });
  });

  return (
    <div>
      <button
        className={`
          btn flex flex-row justify-center items-center
          text-white rounded-lg
          hover:bg-light-gray p-2
          transition-colors
        `}
        ref={setTriggerRef}
        type='button'
        disabled={disabled}
      >
        <OptionsIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `
              w-auto h-auto max-h-full
              border border-[#303436] rounded-lg
              text-white flex flex-col p-1 z-50
              transition-opacity bg-black whitespace-nowrap
            `,
          })}
        >
          <div className='flex flex-col gap-1'>
            <ActionSheetMenuItem
              icon={<AccountIcon />}
              text='Change Role'
              onClick={handleChangeRoleClick}
            />
            <ActionSheetMenuItem
              icon={<TeamIcon className='w-3.5 h-3.5 fill-current' />}
              text='Change Organization'
              onClick={handleChangeOrgClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function UserRow(props: { user: DtoUser; revalidate: () => void }) {
  const user = useMemo(() => fromDTOUser(props.user), [props.user]);

  const hrefToOrg = useMemo(() => {
    if (!user.organizer?.organization?.id || !user.email) return undefined;
    const params = new URLSearchParams();
    params.set('q', user.email);
    return `/admin/organizations/${
      user.organizer.organization.id
    }/members?${params.toString()}`;
  }, [user.organizer?.organization?.id, user.email]);

  const name = user.organizer
    ? OrganizerUtils.GetFullName(user.organizer)
    : user.username;

  return (
    <tr className='text-left text-sms whitespace-nowrap cursor-pointer hover:bg-lp-gray-002 odd:bg-lp-gray-001 h-12.5'>
      <td>
        <div>
          <div className='font-bold text-white truncate'>
            {name ? (
              name
            ) : (
              <span className='italic text-icon-gray'>Unknown</span>
            )}
          </div>
          <div className='pt-1 flex items-center gap-1 min-w-0 text-icon-gray'>
            <div className='flex-shrink text-2xs truncate min-w-0'>
              {user.id}
            </div>
            <IconCopyButton text={user.id} iconSize='w-2.5 h-2.5' />
          </div>
        </div>
      </td>
      <td className='truncate flex items-center gap-1 min-w-0 h-12.5'>
        <div className='flex-shrink truncate min-w-0'>
          {user.email ?? 'N/A'}
        </div>
        <IconCopyButton text={user.email ?? ''} />
      </td>
      <td className='truncate'>{RoleUtils.prettyPrint(user.role)}</td>
      <td className='truncate flex items-center gap-1 min-w-0 h-12.5'>
        {user.organizer?.organization ? (
          <>
            <div className='flex-shrink truncate min-w-0'>
              {user.organizer?.organization.name}
            </div>
            {hrefToOrg && (
              <Link to={hrefToOrg}>
                <NewWindowIcon />
              </Link>
            )}
          </>
        ) : (
          <>N/A</>
        )}
      </td>
      <td className='truncate'>
        {DateUtils.FormatDatetime(props.user.createdAt)}
      </td>
      <td>
        <div
          title={
            user.connectionTestUpdatedAt
              ? `Taken on ${user.connectionTestUpdatedAt}`
              : 'Not Taken'
          }
          className='flex justify-center items-center'
        >
          {user.connectionTestStatus === true ? (
            <GreenCheckIcon className='w-4 h-4' />
          ) : user.connectionTestStatus === false ? (
            <XIcon className='w-4 h-4 fill-[#EE3529]' />
          ) : (
            <>❔</>
          )}
        </div>
      </td>
      <td className='p-0'>
        <div className='h-full flex items-center justify-center'>
          <UserActionSheet user={user} revalidate={props.revalidate} />
        </div>
      </td>
    </tr>
  );
}

function hasFilters(filters: QueryUsersParams): boolean {
  return Object.entries(filters).some(([k, v]) =>
    // don't include includeGuestUsers in the check.
    k === 'searchGuestUsers' ? false : v !== undefined
  );
}

function getKey(filters: QueryUsersParams) {
  return (pageIndex: number, previousPageData: DtoUsersResponse) => {
    // reached the end
    if (previousPageData && !previousPageData.paging.next) return null;

    // first page
    if (pageIndex === 0) return { url: `/users`, args: filters };

    return {
      url: `/users`,
      args: { ...filters, pageToken: previousPageData.paging.next },
    };
  };
}

export function UserList() {
  const { filters, clearFilters } = useUserFilters();
  const hasFiltersSet = useMemo(() => hasFilters(filters), [filters]);

  const { data, error, isLoading, isValidating, setSize, mutate } =
    useSWRInfinite<DtoUsersResponse>(
      getKey(filters),
      async ({ args }) => {
        if (!hasFilters(filters)) return { users: [], paging: {} };
        return (await apiService.user.queryUsers(args)).data;
      },
      {
        keepPreviousData: true,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      }
    );

  const users = useMemo(() => {
    return data?.flatMap((page) => page.users) ?? [];
  }, [data]);

  const hasMore = useMemo(() => {
    if (!data || data.length === 0) return false;
    return Boolean(data[data.length - 1].paging.next);
  }, [data]);

  const handleLoadMore = useLiveCallback(async () => {
    setSize((prev) => prev + 1);
  });

  const { value: showLoading } = useDebouncedValue(isLoading || isValidating, {
    settleAfterMs: 500,
  });

  if (error) return <div className=' text-red-002'>{err2s(error)}</div>;

  const hasRows = users && users.length > 0;
  return (
    <div className='w-full px-10 text-white'>
      <header className='pt-10 text-3xl font-bold'>Users</header>

      <div className='my-4 flex items-center gap-4'>
        <UserFilterControl />
        {hasFiltersSet && (
          <button
            type='button'
            className='btn text-primary text-xs'
            onClick={clearFilters}
          >
            Reset Filters
          </button>
        )}
        {showLoading && <Loading text='' />}
      </div>

      <div className='relative my-8 w-full overflow-x-auto scrollbar'>
        {showLoading && (
          <div className='absolute inset-0 bg-black bg-opacity-50 animate-pulse' />
        )}
        <table className='table table-fixed w-full'>
          <thead>
            <tr className='whitespace-nowrap'>
              <th className='w-3/12'>Name</th>
              <th className='w-3/12'>Email</th>
              <th className='w-1/12'>Role</th>
              <th className='w-2/12'>Organization</th>
              <th className='w-2/12'>Created At</th>
              <th className='w-1/12'>Tech Test</th>
              <th className='w-1/12' />
            </tr>
          </thead>
          <tbody>
            {hasRows && (
              <>
                {users.map((user, index) => (
                  <UserRow key={index} user={user} revalidate={mutate} />
                ))}
              </>
            )}
          </tbody>
        </table>
        {hasMore && (
          <div className='flex items-center justify-center mt-4'>
            <button
              type='button'
              className='btn-secondary w-40 h-10'
              onClick={handleLoadMore}
              disabled={isValidating}
            >
              {isValidating ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        {!hasRows && (
          <div className='py-15 w-full text-center text-white'>
            <div>No users found</div>
            <div>Use the filters above to refine your search</div>
          </div>
        )}
      </div>
    </div>
  );
}
