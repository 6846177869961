import { UserList } from '../components/User/Admin/UserList';
import { AdminView } from '../pages/Admin/AdminView';

export function Component() {
  return (
    <AdminView>
      <UserList />
    </AdminView>
  );
}
